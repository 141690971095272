import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/App.css';
import { updateInstrumentConfig, CRS, Orientation, TimeFormat } from '../utils/api';

const UnitConfigurator = ({ instrumentId }) => {
  const [selectedOptions, setSelectedOptions] = useState({
    crs: '',
    orientation: '',
    timeFormat: ''
  });

  useEffect(() => {
    // Optionally load initial config for the instrumentId if necessary
    // For example, you might want to fetch the existing config from the server or local storage
  }, [instrumentId]);

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setSelectedOptions((prevOptions) => {
      const newOptions = { ...prevOptions, [name]: value };
      updateInstrumentConfig(instrumentId, newOptions); // Update config on change
      return newOptions;
    });
  };

  return (
    <div className="metadata-configurator">
      <h3>Configuration CRS, Orientation, et Format de Temps</h3>

      <div className="option-selector">
        <div className="column">
          <div className="row">
            <label htmlFor="crs">
              CRS :
              <select id="crs" name="crs" value={selectedOptions.crs} onChange={handleOptionChange}>
                <option value="">Sélectionnez le CRS...</option>
                {Object.values(CRS).map((value, idx) => (
                  <option key={idx} value={value}>{value}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="row">
            <label htmlFor="orientation">
              Orientation :
              <select id="orientation" name="orientation" value={selectedOptions.orientation} onChange={handleOptionChange}>
                <option value="">Sélectionnez l'orientation...</option>
                {Object.values(Orientation).map((value, idx) => (
                  <option key={idx} value={value}>{value}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="row">
            <label htmlFor="timeFormat">
              Format de Temps :
              <select id="timeFormat" name="timeFormat" value={selectedOptions.timeFormat} onChange={handleOptionChange}>
                <option value="">Sélectionnez le format de temps...</option>
                {Object.values(TimeFormat).map((value, idx) => (
                  <option key={idx} value={value}>{value}</option>
                ))}
              </select>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

UnitConfigurator.propTypes = {
  instrumentId: PropTypes.number.isRequired,
  file: PropTypes.object
};

export default UnitConfigurator;
