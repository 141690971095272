import axios from 'axios';

// Define Enums
export const CRS = {
  EPSG_4326: 'EPSG:4326',
  EPSG_9849: 'EPSG:9849'
};

export const Orientation = {
  EULER_ANGLES: "Roll, Pitch, Yaw",
  POSITION: "Quaternion (x, y, z, w)"
};

export const TimeFormat = {
  UNIXTIME: 'UnixTime',
  GPSTIME: 'GpsTime (Absolue)',
  DATETIME: 'DateTime (UTC)'
};

// Define Instruments with unique IDs and names
export const instruments = [
  { id: 1, name: '1 MMS Geosimages Lynx SG1' },
  { id: 2, name: '2 FDJ Trion S1' },
  { id: 3, name: '3 SLAM FARO ORBIS' },
  { id: 4, name: '4 SLAM BLK2GO' }, // Pano_BLK_GabrielPeri_CC49 ?
  { id: 5, name: '5 RICOH THETA Z1' },
  { id: 6, name: '6 Insta 360' },
  { id: 7, name: 'Ajouter un profil' },
];

export const configurations = [
  {
    configurationId: 1,
    instrumentId: 1,
    crs: CRS.EPSG_4326,
    orientation: Orientation.EULER_ANGLES,
    timeFormat: TimeFormat.GPSTIME,
    fileName: "Filename",
    capture_time: "GPSTime",
    latitude: "Latitude",
    longitude: "Longitude",
    altitude: "Altitude",
    roll: "Roll",
    pitch: "Pitch",
    yaw: "Heading",
    headerDelimiter: " ",
    contentDelimiter: "\t"
  },
  {
    configurationId: 2,
    instrumentId: 2,
    crs: CRS.EPSG_4326,
    orientation: Orientation.EULER_ANGLES,
    timeFormat: TimeFormat.GPSTIME,
    fileName: "image",
    capture_time: "dateTime",
    latitude: "X",
    longitude: "Y",
    altitude: "Z",
    roll: "Roll",
    pitch: "Pitch",
    yaw: "Heading",
    headerDelimiter: " ",
    contentDelimiter: "\t"
  },
  {
    configurationId: 3,
    instrumentId: 3,
    crs: CRS.EPSG_9849,
    orientation: Orientation.EULER_ANGLES,
    timeFormat: TimeFormat.UNIXTIME,
    fileName: "Image",
    capture_time: "Time",
    latitude: "Y",
    longitude: "X",
    altitude: "Z",
    roll: "Roll",
    pitch: "Pitch",
    yaw: "Heading",
    headerDelimiter: ", ",
    contentDelimiter: ","
  },
];


// Create a lookup object for quick access
const configurationLookup = configurations.reduce((acc, config) => {
  acc[config.instrumentId] = config;
  return acc;
}, {});

// Functions for handling sequences and photos
export const createSequence = async (data) => {
  const session = axios.create();
  try {
    const response = await session.post(`${process.env.REACT_APP_API_URL}/collections`, data);
    if (response.status >= 400) {
      throw new Error('Failed to create collection');
    }
    const sequence_id = response.data?.id;
    if (!sequence_id) {
      throw new Error('No sequence ID returned');
    }
    return response.data;
  } catch (error) {
    console.error('Error creating sequence:', error);
    throw error;
  }
};

export const uploadPhotos = async (selectedFiles, metadata, setUploading, createSequence, setSelectedFiles, selectedInstrument) => {
  if (!selectedFiles.length) {
    alert('No files selected for upload');
    return;
  }

  let sequence_id;

  try {
    setUploading(true);

    const newSeq = await createSequence({ "title": "test" });
    sequence_id = newSeq.id;

    const uploadPromises = selectedFiles.map(async (file, index) => {
      const formData = new FormData();
      const position = index + 1;
      const picturename = file.name;
      formData.append('position', position.toString());
      formData.append('picture', file);
      formData.append('override_Xmp.GPano.ProjectionType', "equirectangular");

      if (selectedInstrument !== '5') {
        if (metadata.hasOwnProperty(picturename)) {
          const fileMetadata = metadata[picturename];
          formData.append('override_longitude', fileMetadata.longitude);
          formData.append('override_latitude', fileMetadata.latitude);
          formData.append('override_Xmp.GPano.GPSAltitude', fileMetadata.altitude);
          formData.append('override_capture_time', fileMetadata.capture_time);
          formData.append('override_Xmp.GPano.PoseRollDegrees', fileMetadata.roll);
          formData.append('override_Xmp.GPano.PosePitchDegrees', fileMetadata.pitch);
          formData.append('override_Exif.GPSInfo.GPSImgDirection', fileMetadata.heading);
          formData.append('override_Xmp.GPano.PoseHeadingDegrees', fileMetadata.heading);
        } else {
          console.warn(`Metadata not found for file: ${picturename}. Skipping file.`);
          return null; // Skip this file by returning null
        }
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/collections/${sequence_id}/items`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.status >= 400) {
          throw new Error(`Failed to upload photo: ${response.statusText}`);
        }
        return response.data;
      } catch (error) {
        console.error(`Error uploading file ${picturename}:`, error.response?.data || error.message || error);
        throw error; // Ensure this error is caught in the outer try/catch
      }
    });

    await Promise.all(uploadPromises.filter(promise => promise !== null)); // Filter out null promises

    alert('All photos uploaded successfully!');
    setSelectedFiles([]);
  } catch (error) {
    console.error('Error uploading photos:', error.response?.data || error.message || error);
    alert('Failed to upload photos');
  } finally {
    setUploading(false);
  }
};

export const createInstrument = (instrumentId, jsonConfig) => {
  if (typeof jsonConfig === 'object' && jsonConfig !== null) {
    configurationLookup[instrumentId] = {
      id: instrumentId,
      name: jsonConfig.name || 'Unknown Instrument',
      configuration: jsonConfig.configuration
    };
  } else {
    console.log(`Invalid configuration provided for Instrument ID ${instrumentId}.`);
  }
};

export const getInstruments = () => {
  const result = instruments.map(instrument => ({
    id: instrument.id,
    name: instrument.name,
    configuration: configurationLookup[instrument.id] || null
  }));
  // console.log('Instruments with configurations:', result);
  return result;
};

export const getConfigByInstrumentId = (instrumentId) => {
  const config = configurationLookup[instrumentId] || 'No config available';
  // console.log(`Configuration for Instrument ID ${instrumentId}:`, config);
  return config;
};

export const updateInstrumentConfig = (instrumentId, newConfig) => {
  if (typeof newConfig === 'object' && newConfig !== null) {
    const existingConfig = configurationLookup[instrumentId];

    if (existingConfig) {
      Object.assign(existingConfig, newConfig);
      // console.log(`Updated configuration for Instrument ID ${instrumentId}: \n${JSON.stringify(existingConfig, null, 2)}`);
    } else {
      configurationLookup[instrumentId] = newConfig;
      // console.log(`Added new configuration for Instrument ID ${instrumentId}: \n${JSON.stringify(newConfig, null, 2)}`);
    }
  } else {
    // console.log(`Invalid configuration provided for Instrument ID ${instrumentId}.`);
  }
};

