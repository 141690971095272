import React from 'react';
import PropTypes from 'prop-types';
import '../styles/App.css'; // Import the CSS file

const MetadataReader = ({ metadata }) => {
  if (!metadata || Object.keys(metadata).length === 0) {
    return <div>Aucune métadonnée disponible pour l'aperçu</div>;
  }

  // Extract all unique keys from the metadata
  const allKeys = [...new Set(Object.values(metadata).flatMap(item => 
    typeof item === 'object' && item !== null 
      ? Object.keys(item) 
      : []
  ))];

  return (
    <div className="preview-metadata">
      <div style={{ maxHeight: '400px', overflowY: 'auto', display: 'block' }}>
        <table>
          <thead>
            <tr>
              <th>Nom du fichier</th>
              {allKeys.map(key => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(metadata).map(([filename, value]) => (
              <tr key={filename}>
                <td>{filename}</td>
                {allKeys.map(colKey => (
                  <td key={colKey}>
                    {typeof value === 'object' && value !== null ? 
                      value[colKey] !== undefined ? 
                        value[colKey] 
                        : '' 
                      : ''}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

MetadataReader.propTypes = {
  metadata: PropTypes.object.isRequired,
};

export default MetadataReader;
