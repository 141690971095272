import React from 'react';

const SingleFileInput = ({ onChange }) => {
  const handleChange = (e) => {
    const file = e.target.files[0];
    onChange(file);
  };

  // Determine the accepted file types and label
  const acceptedFileTypes = '.txt,.csv';
  const labelName = 'Fichier de métadonnées (Single)';

  return (
    <div className="form-group">
      <label htmlFor="SingleFileInput">{labelName} :</label>
      <input
        type="file"
        id="SingleFileInput"
        name="SingleFileInput"
        accept={acceptedFileTypes}
        onChange={handleChange}
        required
      />
    </div>
  );
};

export default SingleFileInput;
