import React, { useState, useEffect } from 'react';
import { getConfigByInstrumentId } from '../utils/api';
import '../styles/App.css'; // Import the CSS file

const InstrumentConfiguration = ({ instrumentId, keysToShow }) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConfig = async () => {
      if (instrumentId) {
        try {
          const fetchedConfig = await getConfigByInstrumentId(instrumentId);
          setConfig(fetchedConfig);
        } catch (error) {
          console.error('Error fetching config:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchConfig();
  }, [instrumentId]);

  if (loading) {
    return <div className="loading">Loading configuration...</div>;
  }

  if (!config) {
    return (
      <div className="no-config">
        No configuration available for Instrument ID {instrumentId}.
      </div>
    );
  }

  return (
    <div className="config-container">
      <h2>Configuration for Instrument ID {instrumentId}</h2>
      <table className="config-table">
        <thead>
          <tr>
            <th>Property</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {keysToShow.map(key => (
            <tr key={key}>
              <td>{key}</td>
              <td>{config[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InstrumentConfiguration;
