import React from 'react';

const NoMetadataFileCheckbox = ({ checked, onChange }) => {
  return (
    <div className="checkbox-container">

<label htmlFor="noMetadataFileCheckbox">Photos géotaguées</label>
      <div className="checkbox-wrapper">
        <input
          type="checkbox"
          id="noMetadataFileCheckbox"
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default NoMetadataFileCheckbox;
