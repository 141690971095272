import React, { useState, useEffect } from 'react';
import '../styles/App.css'; // Import the CSS file
import { getInstruments } from '../utils/api';

const InstrumentSelect = ({ selectedInstrument, onChange }) => {
  const [instrumentList, setInstrumentList] = useState([]);

  useEffect(() => {
    const fetchInstruments = async () => {
      const instruments =  getInstruments();
      setInstrumentList(instruments);
    };

    fetchInstruments();
  }, []);

  return (
    <div className="form-group instrument-select">
      <label htmlFor="instrument">Choisissez un instrument :</label>
      <select
        id="instrument"
        value={selectedInstrument}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Sélectionnez un instrument</option>
        {instrumentList.map((instrument, index) => (
          <option
            key={instrument.id}
            value={instrument.id}
            style={index === instrumentList.length - 1 ? { color: 'green' } : {}}
          >
            {instrument.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InstrumentSelect;
