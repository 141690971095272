import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { pointInPolygon } from '../utils/pointInPolygon';

// Define custom icons for the markers
const useCustomIcons = () => {
  const orangeIcon = useMemo(() => new L.DivIcon({
    className: 'custom-icon',
    html: '<div style="background-color: orange; width: 8px; height: 8px; border-radius: 50%;"></div>',
    iconSize: [10, 10],
  }), []);

  const greenIcon = useMemo(() => new L.DivIcon({
    className: 'custom-icon',
    html: '<div style="background-color: green; width: 8px; height: 8px; border-radius: 50%;"></div>',
    iconSize: [10, 10],
  }), []);

  const redIcon = useMemo(() => new L.DivIcon({
    className: 'custom-icon',
    html: '<div style="background-color: red; width: 8px; height: 8px; border-radius: 50%;"></div>',
    iconSize: [10, 10],
  }), []);

  return { orangeIcon, greenIcon, redIcon };
};

const MapWithPolygon = ({ metadata, onPolygonCreated, centerPosition }) => {
  const [markers, setMarkers] = useState([]);
  const { orangeIcon, greenIcon, redIcon } = useCustomIcons();

  // Update marker colors based on the polygon
  const updateMarkerColors = useCallback((polygon) => {
    setMarkers((prevMarkers) =>
      prevMarkers.map((marker) => {
        const isInside = pointInPolygon(marker.position, polygon);
        return {
          ...marker,
          icon: isInside ? greenIcon : redIcon,
        };
      })
    );
  }, [greenIcon, redIcon]);

  const handlePolygonChange = (layer) => {
    const latLngs = layer.getLatLngs()[0];
    const polygon = latLngs.map(({ lat, lng }) => ({ latitude: lat, longitude: lng }));
    updateMarkerColors(polygon);
    onPolygonCreated(polygon);
  };

  useEffect(() => {
    const metadataArray = Object.values(metadata)
      .map((data, index) => ({
        id: index,
        position: {
          latitude: parseFloat(data.latitude),
          longitude: parseFloat(data.longitude),
        },
        icon: orangeIcon,
      }))
      .filter(point => !isNaN(point.position.latitude) && !isNaN(point.position.longitude));

    setMarkers(metadataArray);
  }, [metadata, orangeIcon]);

  return (
    <MapContainer center={centerPosition} zoom={13} style={{ height: "400px", width: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <FeatureGroup>
        <EditControl
          position="topright"
          onCreated={(e) => handlePolygonChange(e.layer)}
          onEdited={(e) => e.layers.eachLayer(handlePolygonChange)}
          draw={{
            rectangle: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polyline: false,
          }}
        />
        {markers.map((marker) => (
          <Marker
            key={marker.id}
            position={[marker.position.latitude, marker.position.longitude]}
            icon={marker.icon}
          >
            <Popup>
              Latitude: {marker.position.latitude}<br />
              Longitude: {marker.position.longitude}
            </Popup>
          </Marker>
        ))}
      </FeatureGroup>
    </MapContainer>
  );
};

export default MapWithPolygon;
