// index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the updated import
import './index.css';
import App from './App';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';



// Create the root and render the App component
const root = ReactDOM.createRoot(document.getElementById('root')); // Ensure 'root' matches your HTML id
root.render(<App />);
