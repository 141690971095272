// App.js
import React from 'react';
import './styles/App.css';
import PhotoUploadForm from './components/PhotoUploadForm';
import PhotoUploader from './components/PhotoUploader';

const App = () => (
  <div className="App">
    <header className="App-header">
      <center>
      <h1>Géoportail ATGT - Dépôt des photos panoramiques</h1></center>
    </header>
    <main className="App-main">
      <PhotoUploadForm />
    </main>
  </div>
);

export default App;


// // App.js
// import React from 'react';
// import Navbar from './components/Navbar';
// import Form from './components/Form';
// import './styles/App.css';

// const App = () => (
//   <div>
//     <Navbar />
//     <div className="container">
//       <h1 className="form-title">Formulaire Modèle Matériel</h1>
//       <Form />
//     </div>
//   </div>
// );

// export default App;




// import { convertCoordinates } from './convertCoordinates';  // Adjust the path as necessary

// function App() {
//     // Example usage
//     const result = convertCoordinates(1647669.2191, 8178267.4881);

//     return (
//         <div>
//             <h1>Coordinate Conversion</h1>
//             <p>Longitude: {result.longitude}</p>
//             <p>Latitude: {result.latitude}</p>
//         </div>
//     );
// }

// export default App;

// import React, { useEffect, useState } from 'react';
// const { gpsToDateTime, convertCoordinates } = require('./utils/gpsTimeUtils');

// // import { convertCoordinates } from './convertCoordinates';

// function App() {
//     const [coordinates, setCoordinates] = useState({ longitude: null, latitude: null });

//     useEffect(() => {
//         const result = convertCoordinates(1647669.2191, 8178267.4881);
//         setCoordinates(result);
//     }, []);

//     return (
//         <div>
//             <h1>Coordinate Conversion</h1>
//             {coordinates.longitude !== null && coordinates.latitude !== null ? (
//                 <div>
//                     <p>Longitude: {coordinates.longitude}</p>
//                     <p>Latitude: {coordinates.latitude}</p>
//                 </div>
//             ) : (
//                 <p>Loading...</p>
//             )}
//         </div>
//     );
// }

// export default App;
