import React, { useState } from 'react';
import '../styles/App.css'; // Import the CSS file

const MeasurementsForm = () => {
  const [distance, setDistance] = useState('');
  const [period, setPeriod] = useState('');
  const [angle, setAngle] = useState('');

  const handleDistanceChange = (e) => setDistance(e.target.value);
  const handlePeriodChange = (e) => setPeriod(e.target.value);
  const handleAngleChange = (e) => setAngle(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add form submission logic here
    // console.log({ distance, period, angle });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Distance:
          <input
            type="text"
            value={distance}
            onChange={handleDistanceChange}
            placeholder="Enter distance"
          />
        </label>
      </div>
      <div>
        <label>
          Period:
          <input
            type="text"
            value={period}
            onChange={handlePeriodChange}
            placeholder="Enter period"
          />
        </label>
      </div>
      <div>
        <label>
          Angle:
          <input
            type="text"
            value={angle}
            onChange={handleAngleChange}
            placeholder="Enter angle"
          />
        </label>
      </div>
    </form>
  );
};

export default MeasurementsForm;
