// Import proj4 and EPSG definitions
import proj4 from 'proj4';

// Constants for GPS time calculations
const GPS_EPOCH = new Date(Date.UTC(1980, 0, 6, 0, 0, 0));
const SECONDS_IN_WEEK = 604800; // Number of seconds in a week

// Function to format date time to ISO string
function formatDateTime(date) {
    return date.toISOString().split('.')[0]; // Removes milliseconds for simplicity
}

// Function to check if GPS time is absolute
function isAbsoluteGPSTime(gpsTime) {
    const gpsTimeStr = String(gpsTime);
    const decimalIndex = gpsTimeStr.indexOf('.');
    if (decimalIndex === -1) {
        return gpsTimeStr.length > 6;
    } else {
        return decimalIndex > 6;
    }
}


// A REVOIR
// leap second
// Function to convert GPS time to date time
function gpsToDateTime(gpsTime) {
    if (isAbsoluteGPSTime(gpsTime)) {
        // Convert absolute GPS time to Date
        const date = new Date(GPS_EPOCH.getTime() + gpsTime * 1000);
        return formatDateTime(date);
    } else {
        // Convert relative GPS time to Date
        const now = new Date(); // Current date and time
        const gpsEpochTime = GPS_EPOCH.getTime();
        const nowTime = now.getTime();
        const gpsWeeks = Math.floor((nowTime - gpsEpochTime) / (SECONDS_IN_WEEK * 1000));
        const currentWeekStartTime = gpsEpochTime + gpsWeeks * SECONDS_IN_WEEK * 1000;
        const date = new Date(currentWeekStartTime + gpsTime * 1000);
        return formatDateTime(date);
    }
}

// Function to convert Unix time to date time
function unixToDateTime(unixTime) {
    const date = new Date(unixTime * 1000);
    return formatDateTime(date);
}

// Define the coordinate systems
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
proj4.defs("EPSG:9849", "+proj=lcc +lat_0=49 +lon_0=3 +lat_1=48.25 +lat_2=49.75 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");

// Function to convert coordinates from EPSG:9849 to EPSG:4326
function convertCoordinates(x, y, crs_code) {
    const sourceCoord = [x, y];
    const targetCoord = proj4("EPSG:" + crs_code, "EPSG:4326", sourceCoord);
    return {
        longitude: targetCoord[0],
        latitude: targetCoord[1]
    };
}

// Function to convert quaternion to Euler angles
function quaternionToEuler(qx, qy, qz, qw) {
    const roll = Math.atan2(2 * (qw * qx + qy * qz), 1 - 2 * (qx * qx + qy * qy));
    const pitch = Math.asin(Math.max(-1, Math.min(1, 2 * (qw * qy - qz * qx))));
    const yaw = Math.atan2(2 * (qw * qz + qx * qy), 1 - 2 * (qy * qy + qz * qz));

    // Convert radians to degrees
    const rollDeg = roll * (180 / Math.PI);
    const pitchDeg = pitch * (180 / Math.PI);
    const yawDeg = yaw * (180 / Math.PI);

    return {
        roll: rollDeg,  // Return in degrees
        pitch: pitchDeg, // Return in degrees
        yaw: yawDeg // Return in degrees
    };
}

// Export functions
export { gpsToDateTime, unixToDateTime, convertCoordinates, quaternionToEuler };
