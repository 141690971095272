const { getMetadataForImage } = require("./metadata");

// Mock implementations of required classes
class ManySequences {
    constructor() {
        this.sequences = [];
        this.splits = [];
        this.splitParams = [];
    }
}

class Sequence {
    constructor({ title, pictures }) {
        this.title = title;
        this.pictures = pictures;
    }
}

class Split {
    constructor(lastPic, currentPic, reason) {
        this.lastPic = lastPic;
        this.currentPic = currentPic;
        this.reason = reason;
    }
}

// Function to calculate the Haversine distance between two points
function haversine([lat1, lon1], [lat2, lon2], unit = 'meters') {
    const toRad = deg => (deg * Math.PI) / 180;
    const R = unit === 'meters' ? 6371000 : 6371; // Earth's radius in meters or kilometers
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLon / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in the specified unit
}

// The main function to split pictures into sequences// The main function to split pictures into sequences
function splitPicturesIntoSequences(pictures, metadataMap, splitParams, title = '') {
    const sequences = new ManySequences();
    // const metadataMap = {
    //     'Emprise_Chantier027IMG0300.jpg': {
    //         altitude: "126.0368",
    //         capture_time: "2024-08-13T10:04:36",
    //         latitude: "48.6081475430",
    //         longitude: "2.2842335233",
    //         pitch: "0.170587176",
    //         roll: "2.175337423",
    //         yaw: "310.761408151"
    //     },
    //     'Emprise_Chantier027IMG0301.jpg': {
    //         altitude: "126.0368",
    //         capture_time: "2024-08-13T10:04:37",
    //         latitude: "48.6081500000",
    //         longitude: "2.2842350000",
    //         pitch: "0.185745028",
    //         roll: "2.195337423",
    //         yaw: "310.800000000"
    //     },
    //     'Emprise_Chantier027IMG0306.jpg': {
    //         altitude: "126.0368",
    //         capture_time: "2024-08-13T10:04:50",
    //         latitude: "48.6082068948",
    //         longitude: "2.2841304913",
    //         pitch: "0.385745028",
    //         roll: "1.744665911",
    //         yaw: "311.099961791"
    //     },
    //     'Emprise_Chantier027IMG0310.jpg': {
    //         altitude: "125.8743",
    //         capture_time: "2024-08-13T10:05:30",
    //         latitude: "48.6090000000",
    //         longitude: "2.2830000000",
    //         pitch: "0.284574502",
    //         roll: "1.544665911",
    //         yaw: "312.099961791"
    //     },
    //     'Emprise_Chantier027IMG0311.jpg': {
    //         altitude: "125.8743",
    //         capture_time: "2024-08-13T10:05:31",
    //         latitude: "48.6090050000",
    //         longitude: "2.2830050000",
    //         pitch: "0.284574502",
    //         roll: "1.544665911",
    //         yaw: "312.100000000"
    //     },
    //     'Emprise_Chantier027IMG0315.jpg': {
    //         altitude: "125.6543",
    //         capture_time: "2024-08-13T10:06:30",
    //         latitude: "48.6100000000",
    //         longitude: "2.2825000000",
    //         pitch: "0.384574502",
    //         roll: "1.744665911",
    //         yaw: "313.099961791"
    //     },
    //     'Emprise_Chantier027IMG0316.jpg': {
    //         altitude: "125.6543",
    //         capture_time: "2024-08-13T10:06:32",
    //         latitude: "48.6100050000",
    //         longitude: "2.2825050000",
    //         pitch: "0.384574502",
    //         roll: "1.744665911",
    //         yaw: "313.100000000"
    //     },
    //     'Emprise_Chantier027IMG0320.jpg': {
    //         altitude: "125.2343",
    //         capture_time: "2024-08-13T10:07:50",
    //         latitude: "48.6110000000",
    //         longitude: "2.2820000000",
    //         pitch: "0.484574502",
    //         roll: "1.944665911",
    //         yaw: "314.099961791"
    //     },
    //     'Emprise_Chantier027IMG0321.jpg': {
    //         altitude: "125.2343",
    //         capture_time: "2024-08-13T10:07:51",
    //         latitude: "48.6110050000",
    //         longitude: "2.2820050000",
    //         pitch: "0.484574502",
    //         roll: "1.944665911",
    //         yaw: "314.100000000"
    //     },
    //     // Add more images as needed...
    // };
    

    if (!splitParams) {
        sequences.sequences.push(
            new Sequence({
                title: title,
                pictures: pictures,
                splitParams: splitParams
            })
        );
        return sequences;
    }

    let currentPicList = [];

    for (let pic of pictures) {
        const picName = pic.name || "";

        // Try to fetch metadata for the current picture
        const picMetadata = getMetadataForImage(picName, metadataMap);
        if (!picMetadata) {
            console.error(`Metadata not found for image: ${picName}`);
            continue;  // Skip processing this picture if metadata is not found
        }

        if (currentPicList.length === 0) {
            currentPicList.push(pic);
        } else {
            const lastPic = currentPicList[currentPicList.length - 1];
            const lastPicName = lastPic.name || "";

            // Try to fetch metadata for the last picture
            const lastPicMetadata = getMetadataForImage(lastPicName, metadataMap);

            if (!lastPicMetadata) {
                console.error(`Metadata not found for image: ${lastPicName}`);
                currentPicList.push(pic);
                continue;
            }

            // Time delta
            const timeOutOfDelta = splitParams.maxTime
                ? Math.abs(lastPicMetadata.ts - picMetadata.ts) > splitParams.maxTime
                : false;

            // Distance delta
            const distance = haversine(
                [lastPicMetadata.lat, lastPicMetadata.lon],
                [picMetadata.lat, picMetadata.lon],
                'meters'
            );
            const distanceOutOfDelta = splitParams.maxDistance
                ? distance > splitParams.maxDistance
                : false;

            if (timeOutOfDelta || distanceOutOfDelta) {
                let reason = '';

                if (timeOutOfDelta) {
                    reason = `Too much time (${Math.round(Math.abs(lastPicMetadata.ts - picMetadata.ts) / 1000)} seconds)`;
                } else if (distanceOutOfDelta) {
                    reason = `Too much distance (${Math.round(distance)} meters)`;
                }

                sequences.splits.push(new Split(lastPic, pic, reason));
                sequences.sequences.push(
                    new Sequence({
                        title: title,
                        pictures: currentPicList,
                    })
                );
                currentPicList = [pic];
            } else {
                currentPicList.push(pic);
            }
        }
    }

    sequences.sequences.push(
        new Sequence({
            title: title,
            pictures: currentPicList,
        })
    );

    return sequences;
}



module.exports = { splitPicturesIntoSequences };