import React, { useRef, useState } from 'react';
import '../styles/App.css'; // Import the CSS file

const PhotosInput = ({ onChange, isRequired }) => {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    onChange(files);
    setSelectedFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = Array.from(e.dataTransfer.files);
    onChange(droppedFiles);
    setSelectedFiles(droppedFiles);
  };

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="photos-input-container form-group">
      <label htmlFor="photoInput">Sélectionner des photos (JPEG/JPG uniquement) :</label>
      <input
        type="file"
        id="photoInput"
        name="photoInput"
        accept=".jpg, .jpeg"
        multiple
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
        required={isRequired}
      />
      <div
        className={`photo-drop ${selectedFiles.length > 0 ? 'has-photos' : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={openFileInput}
      >
        {selectedFiles.length > 0
          ? `${selectedFiles.length} photo${selectedFiles.length === 1 ? '' : 's'} téléversée${selectedFiles.length === 1 ? '' : 's'}`
          : 'Glissez-déposez les photos ici ou cliquez pour sélectionner'}
      </div>
    </div>
  );
};

export default PhotosInput;
