import React, { useState } from 'react';
import '../styles/App.css'; // Import the CSS file

const Tabs = ({ tabs, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabClick = (tab) => {
    setActiveTab(tab.label);
    onTabChange(tab.label);
  };

  return (
    <div className="tabs">
      <div className="tab-titles">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`tab-title ${activeTab === tab.label ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabs.find((tab) => tab.label === activeTab).content}
      </div>
    </div>
  );
};

export default Tabs;
