// Panel.js
import React, { useState } from 'react';
import '../styles/App.css'; // Import the CSS file

const Panel = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="panel">
      <div className="panel-header" onClick={togglePanel}>
        <span>{title}</span>
        <span className={`arrow ${isOpen ? 'closed' : 'open'}`}>▶</span>
      </div>
      {isOpen && <div className="panel-body">{children}</div>}
    </div>
  );
};

export default Panel;
