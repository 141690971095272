// metadata.js
import EXIFReader from 'exifreader';
import { CRS, getConfigByInstrumentId, Orientation, TimeFormat } from './api';

const { gpsToDateTime, convertCoordinates, unixToDateTime, quaternionToEuler } = require('./spatioTemporalConversions');
const { calculateDistance } = require('./distanceCalculator');
const { pointInPolygon } = require('./pointInPolygon');




// Utility function to read a file as text
const readFileAsText = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
  reader.readAsText(file);
});

// Function to process metadata for MMS Geosimages Lynx SG1 and FDJ Trion S1
// const processGeosimagesLynxAndTrion = async (file) => {
//   const metadataMap = {};

//   const parseMetadataLine = (columns) => {
//     if (columns.length >= 16) {
//       const pictureName = columns[7].trim();
//       metadataMap[pictureName] = {
//         capture_time: gpsToDateTime(columns[5].trim()),
//         latitude: columns[8].trim(),
//         longitude: columns[9].trim(),
//         altitude: columns[10].trim(),
//         roll: columns[11]?.trim(),
//         pitch: columns[12]?.trim(),
//         heading: columns[13]?.trim(),
//       };
//     }
//   };

//   const fileContent = await readFileAsText(file);
//   const lines = fileContent.split('\n').map(line => line.trim()).filter(line => line.length > 0);
//   lines.forEach(line => {
//     const columns = line.split('\t');
//     parseMetadataLine(columns);
//   });

//   return metadataMap;
// };

// Function to process metadata for SLAM FARO ORBIS
const processSlamFeima100 = async (files, capture_time) => {
  try {
    const metadataMap = {};

    const extractMetadata = (lines) => {
      const metadata = {};
      lines.forEach(line => {
        if (line.includes('position =')) {
          const pos = line.split('=')[1].trim().replace(';', '').replace('[', '').replace(']', '').split(',');
          if (pos.length >= 3) {
            const convertedCoordinates = convertCoordinates(parseFloat(pos[0]), parseFloat(pos[1]), "9849");
            metadata.latitude = convertedCoordinates.latitude;
            metadata.longitude = convertedCoordinates.longitude;
            metadata.altitude = parseFloat(pos[2]);
          }
        }
        if (line.includes('orientation =')) {
          const ori = line.split('=')[1].trim().replace(';', '').replace('[', '').replace(']', '').split(',');
          if (ori.length >= 4) {
            const convertedAngles = quaternionToEuler(parseFloat(ori[1]), parseFloat(ori[2]), parseFloat(ori[3]), parseFloat(ori[0]));
            metadata.roll = convertedAngles.roll;
            metadata.pitch = convertedAngles.pitch;
            metadata.heading = convertedAngles.yaw;
          }
        }
      });
      return metadata;
    };

    for (const [index, file] of files.entries()) {
      try {
        const imageName = file.name.split('.')[0] + '.jpg';
        const text = await readFileAsText(file);
        const lines = text.split('\n').map(line => line.trim()).filter(line => line.length > 0);

        const metadata = extractMetadata(lines);

        metadataMap[imageName] = {
          capture_time: capture_time || new Date().toISOString(),
          ...metadata,
        };
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
      }
    }

    return metadataMap;
  } catch (error) {
    console.error('Error reading metadata files:', error);
  }

};

// const processSlamFaroOrbis = async (file) => {
//   const metadataMap = {};

//   const parseFeimaMetadataLine = (columns) => {
//     if (columns.length >= 8) {
//       const pictureName = columns[0].trim();
//       const x = parseFloat(columns[2].trim());
//       const y = parseFloat(columns[3].trim());
//       const z = parseFloat(columns[4].trim());
//       const convertedCoordinates = convertCoordinates(x, y, "9849");
//       if (convertedCoordinates) {
//         metadataMap[pictureName] = {
//           capture_time: unixToDateTime(columns[1].trim()),
//           latitude: convertedCoordinates.latitude,
//           longitude: convertedCoordinates.longitude,
//           altitude: z,
//           heading: columns[5]?.trim(),
//           roll: columns[6]?.trim(),
//           pitch: columns[7]?.trim(),
//         };
//       }
//     }
//   };

//   const fileContent = await readFileAsText(file);
//   const lines = fileContent.split('\n').map(line => line.trim()).filter(line => line.length > 0);
//   lines.slice(1).forEach(line => {
//     const columns = line.split(',');
//     parseFeimaMetadataLine(columns);
//   });

//   return metadataMap;
// };

// export const readSingleFileMetadata = async (instrumentId, file) => {
//   switch (instrumentId) {
//     case '1':
//     case '2':
//       return await processGeosimagesLynxAndTrion(file);
//     case '3':
//       return await processSlamFaroOrbis(file);
//     default:
//       throw new Error(`Unsupported instrument ID: ${instrumentId}`);
//   }
// };

export const readMultipleFilesMetadata = async (files, capture_time) => {
  return await processSlamFeima100(files, capture_time);
};


export const readJPEGMetadata = async (files) => {
  try {
    const metadataMap = {};

    for (const file of files) {
      const arrayBuffer = await file.arrayBuffer();
      const tags = EXIFReader.load(arrayBuffer);

      const picturename = file.name;
      const capture_time = tags.DateTimeOriginal?.description || new Date().toISOString();

      const latitude = tags.GPSLatitude && tags.GPSLatitude.description
        ? tags.GPSLatitude.description
        : null;
      const longitude = tags.GPSLongitude && tags.GPSLongitude.description
        ? tags.GPSLongitude.description
        : null;
      const altitude = tags.GPSAltitude?.description || null;
      const heading = tags.GPSImgDirection?.description || null;
      const roll = tags.Roll?.description || null;
      const pitch = tags.Pitch?.description || null;

      metadataMap[picturename] = {
        capture_time,
        latitude,
        longitude,
        altitude,
        roll,
        pitch,
        heading,
      };
    }

    return metadataMap;
  } catch (error) {
    console.error('Error reading JPEG metadata:', error);
    throw error;
  }
};


export const readMetadataFromFile = async (instrumentId, file) => {

  const config = await getConfigByInstrumentId(instrumentId);

  if (config === 'No config available') {
    throw new Error(`Configuration not found for instrument ID: ${instrumentId}`);
  }

  const metadataMap = {};
  const headerDelimiter = config.headerDelimiter;
  const contentDelimiter = config.contentDelimiter;

  const fileContent = await readFileAsText(file);
  const lines = fileContent.split('\n').map(line => line.trim()).filter(line => line.length > 0);

  const headersLine = lines[0];
  const headers = headersLine.split(headerDelimiter).map(header => header.trim());
  console.log('Headers:', headers);

  const columnIndexMapping = {
    pictureName: headers.indexOf(config.fileName.trim()),
    capture_time: headers.indexOf(config.capture_time.trim()),
    latitude: headers.indexOf(config.latitude.trim()),
    longitude: headers.indexOf(config.longitude.trim()),
    altitude: headers.indexOf(config.altitude.trim()),
    roll: headers.indexOf(config.roll.trim()),
    pitch: headers.indexOf(config.pitch.trim()),
    yaw: headers.indexOf(config.yaw.trim()),
  };

  const parseMetadataLine = (columns) => {
    if (columns.length > 0) {
      const pictureName = columns[columnIndexMapping.pictureName]?.trim();
      if (!pictureName) {
        console.warn('No picture name found for line:', columns);
        return;
      }

      const rawCaptureTime = columns[columnIndexMapping.capture_time]?.trim();
      const rawLatitude = columns[columnIndexMapping.latitude]?.trim();
      const rawLongitude = columns[columnIndexMapping.longitude]?.trim();
      const rawAltitude = columns[columnIndexMapping.altitude]?.trim();
      const rawRoll = columns[columnIndexMapping.roll]?.trim();
      const rawPitch = columns[columnIndexMapping.pitch]?.trim();
      const rawYaw = columns[columnIndexMapping.yaw]?.trim();

      let capture_time, latitude, longitude, altitude, roll, pitch, yaw;

      if (config.timeFormat === TimeFormat.GPSTIME) {
        capture_time = gpsToDateTime(rawCaptureTime);

      } else if (config.timeFormat === TimeFormat.UNIXTIME) {
        capture_time = unixToDateTime(rawCaptureTime);
      } else {
        capture_time = rawCaptureTime;
      }

      if (config.crs === CRS.EPSG_9849 && rawLatitude && rawLongitude) {
        const coords = convertCoordinates(parseFloat(rawLongitude), parseFloat(rawLatitude), "9849");
        latitude = coords.latitude;
        longitude = coords.longitude;
      } else {
        latitude = rawLatitude;
        longitude = rawLongitude;
      }

      altitude = rawAltitude;

      if (config.orientation === Orientation.POSITION && rawRoll && rawPitch && rawYaw) {
        const euler = quaternionToEuler(parseFloat(rawRoll), parseFloat(rawPitch), parseFloat(rawYaw), 1);
        roll = euler.roll;
        pitch = euler.pitch;
        yaw = euler.yaw;
      } else {
        roll = rawRoll;
        pitch = rawPitch;
        yaw = rawYaw;
      }

      metadataMap[pictureName] = {
        capture_time: capture_time,
        latitude: latitude,
        longitude: longitude,
        altitude: altitude,
        roll: roll,
        pitch: pitch,
        yaw: yaw,
      };
    }
  };

  lines.slice(1).forEach(line => {
    const columns = line.split(contentDelimiter);
    parseMetadataLine(columns);
  });
  console.log(metadataMap);

  return metadataMap;
};


export const filterMetadataByDistance = (metadataMap, minDistance) => {

  const metadataArray = Object.entries(metadataMap).map(([picturename, data]) => ({
    picturename,
    ...data
  })).sort((a, b) => new Date(a.capture_time) - new Date(b.capture_time));

  const getDistance = (point1, point2) => {
    return calculateDistance(
      parseFloat(point1.latitude),
      parseFloat(point1.longitude),
      parseFloat(point2.latitude),
      parseFloat(point2.longitude)
    );
  };

  const filteredMetadata = [];
  let previousPoint = null;

  for (const point of metadataArray) {
    if (!previousPoint || getDistance(previousPoint, point) >= minDistance) {
      filteredMetadata.push(point);
      previousPoint = point;
    }
  }

  const filteredMetadataMap = filteredMetadata.reduce((acc, point) => {
    acc[point.picturename] = {
      capture_time: point.capture_time,
      latitude: point.latitude,
      longitude: point.longitude,
      altitude: point.altitude,
      roll: point.roll,
      pitch: point.pitch,
      heading: point.heading,
    };
    return acc;
  }, {});

  return filteredMetadataMap;
};




export const filterMetadataByPolygon = (metadataMap, polygon) => {
  const filteredMetadataMap = Object.entries(metadataMap)
    .filter(([_, data]) => pointInPolygon({ latitude: data.latitude, longitude: data.longitude }, polygon))
    .reduce((acc, [picturename, data]) => {
      acc[picturename] = data;
      return acc;
    }, {});

  return filteredMetadataMap;
};

// Function to get metadata for a specific image
export const getMetadataForImage = (imageName, metadataMap) => {
  // Retrieve metadata for the given image name from the metadata map
  const metadata = metadataMap[imageName];

  // If metadata is not found, log a warning and return null
  if (!metadata) {
    console.warn(`Metadata for image ${imageName} not found.`);
    return null;
  }

  // Convert necessary metadata properties to the correct data types if needed
  return {
    altitude: parseFloat(metadata.altitude),  // Convert altitude to a number
    ts: new Date(metadata.capture_time).getTime(),  // Convert capture_time to timestamp in milliseconds
    lat: parseFloat(metadata.latitude),  // Convert latitude to a number
    lon: parseFloat(metadata.longitude),  // Convert longitude to a number
    pitch: parseFloat(metadata.pitch),  // Convert pitch to a number
    roll: parseFloat(metadata.roll),  // Convert roll to a number
    yaw: parseFloat(metadata.yaw)  // Convert yaw to a number
  };
};

// export function getMetadataForImage(picName) {
//   // Metadata object storing timestamps and geographical coordinates for each image
//   const metadata = {
//       'pic1.jpg': { ts: 1609459200000, lat: 40.7128, lon: -74.0060 }, // Jan 1, 2021, 00:00:00, NYC
//       'pic2.jpg': { ts: 1609459260000, lat: 40.7128, lon: -74.0061 }, // Jan 1, 2021, 00:01:00, NYC (close)
//       'pic3.jpg': { ts: 1609462860000, lat: 40.7306, lon: -73.9352 }, // Jan 1, 2021, 01:01:00, Brooklyn (far)
//       'pic4.jpg': { ts: 1609462920000, lat: 40.7306, lon: -73.9353 }, // Jan 1, 2021, 01:02:00, Brooklyn (close)
//       'pic5.jpg': { ts: 1609466520000, lat: 40.748817, lon: -73.985428 }, // Jan 1, 2021, 02:01:00, Empire State Building
//       'pic6.jpg': { ts: 1609470120000, lat: 40.689247, lon: -74.044502 }, // Jan 1, 2021, 03:01:00, Statue of Liberty
//       // Add more entries as needed...
//   };
  
//   // Return the metadata object for the given picture name
//   return metadata[picName] || null;  // Return null if metadata is not found to handle the case where the picture name doesn't exist
// }
