import React from 'react';

const MultipleFileInput = ({ onChange }) => {
  const handleChange = (e) => {
    const files = Array.from(e.target.files);
    onChange(files);
  };

  // Determine the accepted file types and label
  const acceptedFileTypes = '.txt,.csv';
  const labelName = 'Fichier(s) de métadonnées (Multiple)';

  return (
    <div className="form-group">
      <label htmlFor="MultipleFileInput">{labelName} :</label>
      <input
        type="file"
        id="MultipleFileInput"
        name="MultipleFileInput"
        accept={acceptedFileTypes}
        onChange={handleChange}
        multiple
        required
      />
    </div>
  );
};

export default MultipleFileInput;
