import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/App.css'; // Import the CSS file
import { updateInstrumentConfig } from '../utils/api'; // Adjust the import path as necessary

const initialConfig = {
  fileName: '',
  date: '',
  latitude: '',
  longitude: '',
  altitude: '',
  roll: '',
  pitch: '',
  heading: ''
};

const MetadataConfigurator = ({ instrumentId, file }) => {
  const [separator, setSeparator] = useState(',');
  const [headers, setHeaders] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(initialConfig);

  useEffect(() => {
    if (file) {
      // console.log("Processing file:", file);
      processFile(file, separator);
    }
  }, [file, separator]);

  const processFile = (file, sep) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const lines = content.split('\n');
      if (lines.length > 0) {
        const headersLine = lines[0];
        setHeaders(headersLine.split(sep));
      }
    };
    reader.readAsText(file);
  };

  const handleSeparatorChange = (event) => {
    const newSeparator = event.target.value;
    setSeparator(newSeparator);
  };

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setSelectedOptions((prevOptions) => {
      const newOptions = { ...prevOptions, [name]: value };
      updateInstrumentConfig(instrumentId, newOptions);
      return newOptions;
    });
  };

  const getAvailableOptions = (fieldName) => {
    const selectedValues = Object.values(selectedOptions);
    return headers.filter(header => !selectedValues.includes(header) || header === selectedOptions[fieldName]);
  };

  return (
    <div className="metadata-configurator">
      <h3>Configuration des Métadonnées</h3>
      
      <div className="separator-selector">
        <label htmlFor="separator">Sélectionnez le séparateur :</label>
        <select id="separator" value={separator} onChange={handleSeparatorChange}>
          <option value=",">Virgule (,)</option>
          <option value=";">Point-virgule (;)</option>
          <option value="\t">Tabulation (\t)</option>
          <option value=" ">Espace (&nbsp;)</option>
        </select>
      </div>
      
      <div className="option-selector">
        <div className="column">
          {['fileName', 'latitude', 'longitude', 'altitude'].map((field, index) => (
            <div className="row" key={index}>
              <label htmlFor={field}>
                {field.replace(/([A-Z])/g, ' $1').toUpperCase()} :
                <select id={field} name={field} value={selectedOptions[field]} onChange={handleOptionChange}>
                  <option value="">Sélectionnez la colonne...</option>
                  {getAvailableOptions(field).map((header, idx) => (
                    <option key={idx} value={header}>{header}</option>
                  ))}
                </select>
              </label>
            </div>
          ))}
        </div>
        
        <div className="column">
          {['date', 'roll', 'pitch', 'heading'].map((field, index) => (
            <div className="row" key={index}>
              <label htmlFor={field}>
                {field.replace(/([A-Z])/g, ' $1').toUpperCase()} :
                <select id={field} name={field} value={selectedOptions[field]} onChange={handleOptionChange}>
                  <option value="">Sélectionnez la colonne...</option>
                  {getAvailableOptions(field).map((header, idx) => (
                    <option key={idx} value={header}>{header}</option>
                  ))}
                </select>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

MetadataConfigurator.propTypes = {
  instrumentId: PropTypes.number.isRequired,
  file: PropTypes.object // Prop type for the file
};

export default MetadataConfigurator;
