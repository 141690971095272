import React, { useState, useCallback, useEffect } from 'react';
import '../styles/App.css';
import PhotosInput from './PhotosInput';
import SingleFileInput from './SingleFileInput';
import MultipleFileInput from './MultipleFileInput';
import {
  filterMetadataByDistance,
  readMetadata,
  filterMetadataByPolygon,
  readJPEGMetadata,
  readMultipleFilesMetadata,
  readSingleFileMetadata,
  readMetadataFromFile,
  getMetadataForImage
} from '../utils/metadata';
import { createSequence, uploadPhotos, getConfigByInstrumentId } from '../utils/api';
import InstrumentSelect from './InstrumentSelect';
import MetadataConfigurator from './MetadataConfigurator';
import PreviewMetadata from './PreviewMetadata';
import InstrumentConfiguration from './InstrumentConfiguration';
import Tabs from './Tabs';
import Panel from './Panel';
import MapWithPolygon from './MapWithPolygon';
import TempoSpatialFilter from './TempoSpatialFilter';
import NoMetadataFileCheckbox from './NoMetadataFileCheckbox';
import UnitConfigurator from './UnitConfigurator';
import { gpsToDateTime } from '../utils/spatioTemporalConversions';
import { splitPicturesIntoSequences } from '../utils/split';
import KMLFileInput from './KMLFileInput';

const PhotoUploadForm = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [metadataFile, setMetadataFile] = useState(null);
  const [metadata, setMetadata] = useState({});
  const [photoMetadata, setPhotoMetadata] = useState({});
  const [instrumentId, setInstrumentId] = useState();
  const [metadataFileSelected, setMetadataFileSelected] = useState(false);
  const [activeTab, setActiveTab] = useState('Configuration View');
  const [showPanels, setShowPanels] = useState(true);
  const [polygon, setPolygon] = useState(null);
  const [filteredMetadataByDistance, setFilteredMetadataByDistance] = useState({});
  const [filteredMetadataByPolygon, setFilteredMetadataByPolygon] = useState({});
  const [filteredMetadataByDistanceAndPolygon, setFilteredMetadataByDistanceAndPolygon] = useState({});
  const [minDistance, setMinDistance] = useState(0);
  const [centerPosition, setCenterPosition] = useState([43.505, -0.09]);
  const [captureTime, setCaptureTime] = useState('');

  // Handle checkbox change for showing panels
  const handleCheckboxChange = (e) => {
    setShowPanels(!e.target.checked);
  };

  // Handle instrument selection change
  const handleInstrumentChange = (instrument) => {
    // console.log('Selected Instrument ID:', instrument);
    setInstrumentId(instrument);
    setSelectedFiles([]);
    setMetadataFile(null);
    setMetadata({});
    setMetadataFileSelected(false);
    setFilteredMetadataByDistance({});
    setFilteredMetadataByPolygon({});
    setFilteredMetadataByDistanceAndPolygon({});
  };

  // Handle change in photo input
  const handlePhotosInputChange = async (files) => {
    setSelectedFiles(files);

    if (files.length > 0) {
      if (!metadataFileSelected) {
        try {
          const photoMetadataMap = await readJPEGMetadata(files);
          setPhotoMetadata(photoMetadataMap);
          const combinedMetadata = { ...metadata, ...photoMetadataMap };
          setMetadata(combinedMetadata);
        } catch (error) {
          console.error('Error extracting metadata:', error);
        }
      }
    }
  };
  
  const handleMetadataSingleFileChange = async (file, instrumentId) => {
    setMetadataFile(file);
    setMetadataFileSelected(true);

    if (file) {
      const metadataMap = await readMetadataFromFile(instrumentId, file);
      setMetadata(metadataMap);
      setFilteredMetadataByPolygon(metadataMap);


      const pictures = [
        { name: "Emprise_Chantier027IMG0300.jpg" },
        { name: "Emprise_Chantier027IMG0306.jpg" },
        { name: "Emprise_Chantier027IMG0310.jpg" },
        { name: "Emprise_Chantier027IMG0315.jpg" },
        { name: "Emprise_Chantier027IMG0320.jpg" },
        { name: "Emprise_Chantier027IMG0301.jpg" },
        { name: "Emprise_Chantier027IMG0311.jpg" },
        { name: "Emprise_Chantier027IMG0316.jpg" },
        { name: "Emprise_Chantier027IMG0321.jpg" },
    ];

      const splitParams = {
        maxTime: 100,
        maxDistance: 1
      };


      const result = splitPicturesIntoSequences(pictures,metadataMap, splitParams, "Example Sequence");


      console.log("Sequences:", result.sequences);
      console.log("Splits:", result.splits);


    }
  };

  // Handle multiple metadata files input change
  const handleMetadataMultipleFilesChange = async (files) => {
    setMetadataFile(files);
    setMetadataFileSelected(true);

    if (files) {
      const metadataMap = await readMultipleFilesMetadata(files, captureTime);
      setMetadata(metadataMap);
      setFilteredMetadataByPolygon(metadataMap);
    }
  };


  // Handle polygon creation and metadata filtering by polygon
  const handlePolygonCreated = useCallback((polygon) => {
    setPolygon(polygon);
    if (polygon) {
      const filteredByPolygon = filterMetadataByPolygon(metadata, polygon);
      setFilteredMetadataByPolygon(filteredByPolygon);
    }
  }, [metadata]);

  // Filter metadata by distance
  useEffect(() => {
    if (metadata) {
      const filteredByDistance = filterMetadataByDistance(metadata, minDistance);
      setFilteredMetadataByDistance(filteredByDistance);
    }
  }, [metadata, minDistance]);

  // Intersect metadata by distance and polygon
  useEffect(() => {
    const intersectMetadata = (metadataByDistance, metadataByPolygon) => {
      const filteredIntersection = {};
      for (const key in metadataByDistance) {
        if (metadataByPolygon[key]) {
          filteredIntersection[key] = metadataByDistance[key];
        }
      }
      return filteredIntersection;
    };

    if (filteredMetadataByDistance && filteredMetadataByPolygon) {
      const filteredIntersection = intersectMetadata(filteredMetadataByDistance, filteredMetadataByPolygon);
      setFilteredMetadataByDistanceAndPolygon(filteredIntersection);
    }
  }, [filteredMetadataByDistance, filteredMetadataByPolygon]);

  // Calculate the center position based on filtered metadata
  useEffect(() => {
    if (filteredMetadataByDistanceAndPolygon) {
      const metadataArray = Object.values(filteredMetadataByDistanceAndPolygon)
        .map(data => ({
          latitude: parseFloat(data.latitude),
          longitude: parseFloat(data.longitude),
        }))
        .filter(point => !isNaN(point.latitude) && !isNaN(point.longitude));

      if (metadataArray.length > 0) {
        const avgLat = metadataArray.reduce((sum, point) => sum + point.latitude, 0) / metadataArray.length;
        const avgLng = metadataArray.reduce((sum, point) => sum + point.longitude, 0) / metadataArray.length;
        setCenterPosition([avgLat, avgLng]);
      }
    }
  }, [filteredMetadataByDistanceAndPolygon]);

  // Handle minimum distance change
  const handleMinDistanceChange = (e) => {
    setMinDistance(Number(e.target.value));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!instrumentId) {
      alert('Veuillez sélectionner un instrument');
      return;
    }
    try {
      await uploadPhotos(
        selectedFiles,
        { ...filteredMetadataByDistanceAndPolygon, instrument: instrumentId },
        setUploading,
        createSequence,
        setSelectedFiles,
        instrumentId
      );
    } catch (error) {
      console.error('Erreur dans handleSubmit :', error.message);
    }
  };

  // Keys for various configurations
  const keysForCRSOrientationTime = ['crs', 'orientation', 'timeFormat'];
  const keysForMetadata = ['fileName', 'dateTime', 'latitude', 'longitude', 'altitude', 'roll', 'pitch', 'yaw'];

  // Tabs configuration for instrument and units
  const columnsTabs = [
    {
      label: 'Configuration View',
      content: (
        <InstrumentConfiguration instrumentId={instrumentId} keysToShow={keysForMetadata} />
      ),
    },
    {
      label: 'Edit Configuration',
      content: (
        <MetadataConfigurator
          instrumentId={instrumentId}
          file={metadataFile}
        />
      ),
    },
  ];

  const UnitsTabs = [
    {
      label: 'Configuration View',
      content: (
        <InstrumentConfiguration instrumentId={instrumentId} keysToShow={keysForCRSOrientationTime} />
      ),
    },
    {
      label: 'Edit Configuration',
      content: (
        <UnitConfigurator
          instrumentId={instrumentId}
          file={metadataFile}
        />
      ),
    },
  ];

  return (
    <div className="App">

      <form onSubmit={handleSubmit} className="container">
        <h2>Téléverser des photos panoramiques</h2>
        <div className="instrument-checkbox-container">
          <div className="instrument-select">
            <InstrumentSelect
              instrumentId={instrumentId}
              onChange={handleInstrumentChange}
            />
          </div>
          <div className="no-metadata-checkbox">
            <NoMetadataFileCheckbox
              checked={!showPanels}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        {instrumentId && (
          <>
            {showPanels && (
              <>
                {instrumentId !== "4" ? (
                  <SingleFileInput
                    key="single-file-input"
                    onChange={(file) => handleMetadataSingleFileChange(file, instrumentId)}
                  />
                ) : (<>
                  <MultipleFileInput
                    key="multiple-file-input"
                    onChange={(files) => handleMetadataMultipleFilesChange(files, instrumentId)}
                  />

                </>
                )}

                {instrumentId !== "4" ? (
                  <Panel title="Configurations">
                    <Tabs
                      tabs={UnitsTabs}
                      onTabChange={(tabLabel) => setActiveTab(tabLabel)}
                    />
                    <Tabs
                      tabs={columnsTabs}
                      onTabChange={(tabLabel) => setActiveTab(tabLabel)}
                    />
                  </Panel>
                ) : <div className="capture-time-input">
                  <label htmlFor="captureTime">Capture Time:</label>
                  <input
                    type="datetime-local"
                    id="captureTime"
                    value={captureTime}
                    onChange={(e) => setCaptureTime(e.target.value)}
                  />
                </div>
                }

                <Panel title="Metadata">
                  <input
                    type="number"
                    value={minDistance}
                    onChange={handleMinDistanceChange}
                    placeholder="Min Distance"
                  /><KMLFileInput>


                  </KMLFileInput>
                  <MapWithPolygon
                    metadata={filteredMetadataByDistanceAndPolygon}
                    onPolygonCreated={handlePolygonCreated}
                    centerPosition={centerPosition}
                  />
                  <PreviewMetadata
                    metadata={filteredMetadataByDistanceAndPolygon}
                  />
                </Panel>
              </>
            )}

            <div className="buttons-container">
              <PhotosInput onChange={handlePhotosInputChange} />
            </div>

            {!showPanels && (
              <Panel title="Metadata">
                <input
                  type="number"
                  value={minDistance}
                  onChange={handleMinDistanceChange}
                  placeholder="Min Distance"
                />
                <MapWithPolygon
                  metadata={photoMetadata}
                  onPolygonCreated={handlePolygonCreated}
                  centerPosition={centerPosition}
                />
                <PreviewMetadata
                  metadata={photoMetadata}
                />
              </Panel>
            )}
            <Panel title="Creation des sequences">
              <TempoSpatialFilter />
            </Panel>
            <button className="submit-btn" type="submit" disabled={!instrumentId || uploading}>
              {uploading ? 'Téléversement en cours...' : 'Téléverser les photos'}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default PhotoUploadForm;
