import React from 'react';

const KMLFileInput = ({ onChange }) => {
  const handleChange = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      
      reader.onload = (event) => {
        const kmlContent = event.target.result;

        try {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(kmlContent, "application/xml");

          const coordinates = xmlDoc.getElementsByTagName('coordinates')[0]?.textContent.trim();
          
          if (coordinates) {
            const polygon = coordinates.split(' ').map(coordStr => {
              const [lng, lat] = coordStr.split(',').map(Number);
              return { lat, lng };
            });
            
            onChange(polygon);
          } else {
            throw new Error("No polygon coordinates found in the KML file.");
          }
        } catch (error) {
          console.error("Failed to parse KML:", error);
          alert("The file is not a valid KML file or does not contain a valid polygon.");
        }
      };
      
      reader.readAsText(file);
    }
  };

  const acceptedFileTypes = '.kml';
  const labelName = 'KML Polygon File';

  return (
    <div className="form-group">
      <label htmlFor="KMLFileInput">{labelName} :</label>
      <input
        type="file"
        id="KMLFileInput"
        name="KMLFileInput"
        accept={acceptedFileTypes}
        onChange={handleChange}
        required
      />
    </div>
  );
};

export default KMLFileInput;
